// 壁纸网站：https://wallhaven.cc/
export default [
    'https://w.wallhaven.cc/full/1k/wallhaven-1ky369.jpg',
    'https://w.wallhaven.cc/full/y8/wallhaven-y8lqo7.jpg',
    'https://w.wallhaven.cc/full/wq/wallhaven-wqve97.png',
    'https://w.wallhaven.cc/full/pk/wallhaven-pkgkkp.png',
    'https://w.wallhaven.cc/full/z8/wallhaven-z8dg9y.png',
    'https://w.wallhaven.cc/full/72/wallhaven-72rxqo.jpg',
    'https://w.wallhaven.cc/full/72/wallhaven-72rd8e.jpg',
    'https://w.wallhaven.cc/full/57/wallhaven-5758y8.jpg',
    'https://w.wallhaven.cc/full/dp/wallhaven-dpo38l.jpg',
    'https://w.wallhaven.cc/full/pk/wallhaven-pkw6y3.jpg',
    'https://w.wallhaven.cc/full/8o/wallhaven-8oky1j.jpg',
    'https://w.wallhaven.cc/full/8o/wallhaven-8oev1j.jpg',
    'https://w.wallhaven.cc/full/8o/wallhaven-8ok7vk.jpg',
    'https://w.wallhaven.cc/full/g7/wallhaven-g75r7d.jpg',
    'https://w.wallhaven.cc/full/9m/wallhaven-9mkydk.jpg',
    'https://w.wallhaven.cc/full/6o/wallhaven-6oyzlq.jpg',
    'https://w.wallhaven.cc/full/0p/wallhaven-0ppoe0.jpg',
    'https://w.wallhaven.cc/full/0j/wallhaven-0jg8qw.jpg',
    'https://w.wallhaven.cc/full/j5/wallhaven-j5kz75.jpg',
    'https://w.wallhaven.cc/full/q6/wallhaven-q6kpr7.png',
    'https://w.wallhaven.cc/full/nz/wallhaven-nzjv6o.jpg',
    'https://w.wallhaven.cc/full/g8/wallhaven-g82vm7.jpg',
    'https://w.wallhaven.cc/full/2e/wallhaven-2ezqxm.jpg',
    'https://w.wallhaven.cc/full/72/wallhaven-722pqo.jpg',
    'https://w.wallhaven.cc/full/ne/wallhaven-nepj7o.jpg',
    'https://w.wallhaven.cc/full/4l/wallhaven-4lmz3l.jpg',
    'https://w.wallhaven.cc/full/39/wallhaven-39rqjy.png',
    'https://w.wallhaven.cc/full/0q/wallhaven-0q16r5.jpg',
    'https://w.wallhaven.cc/full/k7/wallhaven-k763z1.png',
    'https://w.wallhaven.cc/full/x8/wallhaven-x8l9jz.jpg',
    'https://w.wallhaven.cc/full/49/wallhaven-49grp8.jpg',
    'https://w.wallhaven.cc/full/j8/wallhaven-j8m2gm.jpg',
    'https://w.wallhaven.cc/full/g8/wallhaven-g8goze.jpg',
    'https://w.wallhaven.cc/full/4g/wallhaven-4gpk17.png',
    'https://w.wallhaven.cc/full/e7/wallhaven-e79dpo.jpg',
    'https://w.wallhaven.cc/full/95/wallhaven-9566pd.jpg',
    'https://w.wallhaven.cc/full/3k/wallhaven-3kpj8y.png',
    'https://w.wallhaven.cc/full/l3/wallhaven-l3gq6q.jpg',
    'https://w.wallhaven.cc/full/nk/wallhaven-nk5xz1.jpg',
    'https://w.wallhaven.cc/full/nk/wallhaven-nk67jd.jpg'
]