const list_16_1 = [
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220205/1644039189570555.png",
        "url":"https://dexscreener.com/bsc/pancakeswap",
        "url_name":"DEX筛选器",
        "tip":"实时跟踪和绘制您最喜欢的DEX图表"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220208/1644289410267198.png",
        "url":"https://debank.com/",
        "url_name":"钱包监控",
        "tip":"关注巨鲸、机构钱包地址监控，发现最新投资标的"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220203/1643893639390275.png",
        "url":"https://tokentool.app/createToken/eth",
        "url_name":"一键发币",
        "tip":"一键发行ETH、BSC等资产，低成本小白可用"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220204/1643957815250843.png",
        "url":"https://avedex.cc/check",
        "url_name":"貔貅筛查",
        "tip":"合约貔貅检测工具，防止冲土狗上当"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220127/1643287796813079.png",
        "url":"https://mycointool.com/GasNow",
        "url_name":"实时GAS费",
        "tip":"多链Gas费用实时查看，省钱工具"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220203/1643893958983769.png",
        "url":"https://tokentool.app/robotTrade/grabPreSale",
        "url_name":"抢币神器",
        "tip":"BSC链预购抢购助手，科学家专用"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220205/1644037556666611.png",
        "url":"https://tokentool.app/oneToMore/eth",
        "url_name":"批量转账",
        "tip":"批量转账工具，走的是合约比较省gas"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220315/1647336190192346.png",
        "url":"https://disperse.app/?continueFlag=84553ec2525040264a3b212e746e2692",
        "url_name":"Disperse",
        "tip":"以太坊分散转账器"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220315/1647336112832273.png",
        "url":"https://bip39.onekey.so/",
        "url_name":"Onekey",
        "tip":"助记词转换器，大钱包无用此工具"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220210/1644424764138586.png",
        "url":"https://apesafe.io/",
        "url_name":"ApeSafe",
        "tip":"智能合约代码对比"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220209/1644389934771626.png",
        "url":"https://github.com/MycoinTool/revinest",
        "url_name":"自动复投脚本",
        "tip":"DeFi 挖矿自动复投脚本，附使用方法"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220205/1644038180992613.png",
        "url":"http://139.129.119.86:8080/",
        "url_name":"币种持仓分析",
        "tip":"新币种持仓地址增加监控工具，发现优质新笔"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220203/1643894498292170.png",
        "url":"https://tokentool.app/game/armzlegends",
        "url_name":"链游助手",
        "tip":"掰手腕 ArmzLegends 工具"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220203/1643898400351436.png",
        "url":"https://tornado.cash/",
        "url_name":"Tornado",
        "tip":"混币工具"
    }
]

const list_16_2 = [
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220205/1644037318474094.png",
        "url":"https://icy.tools/",
        "url_name":"NFT趋势",
        "tip":"NFT趋势工具"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220203/1643896674769039.png",
        "url":"https://nftgo.io/",
        "url_name":"NFTGO数据聚合平台",
        "tip":"NFT市场指标巨鲸追踪，排名数据等聚合平台"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220205/1644038835852384.png",
        "url":"https://nftgo.io/whale-tracking/trade",
        "url_name":"巨鲸跟踪",
        "tip":"监控巨鲸买NFT动向的网站"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220208/1644328397847935.png",
        "url":"https://dappradar.com/nft",
        "url_name":"Dappradar",
        "tip":"查询近期热门NFT数据及排行榜"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220401/1648801789803764.png",
        "url":"https://context.app/feed/collectors",
        "url_name":"Context",
        "tip":"NFT数据追踪工具"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220208/1644332944319795.png",
        "url":"https://opensea.io/",
        "url_name":"Opensea",
        "tip":"最大的NFT交易市场，天下苦Opensea久已"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220208/1644327009822167.png",
        "url":"https://shucang.cn/",
        "url_name":"数藏中国",
        "tip":"国内合规的NFT交易平台，支持法币交易"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220208/1644305936782416.png",
        "url":"https://double.one/",
        "url_name":"NFT租赁",
        "tip":"NFT游戏租赁协议"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220208/1644305789669677.png",
        "url":"https://pine.loans/",
        "url_name":"NFT抵押借贷",
        "tip":""
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220205/1644039006687941.png",
        "url":"https://icodrops.com/",
        "url_name":"ICO预告",
        "tip":"获取最新ICO预告信息"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220205/1644037261702357.png",
        "url":"https://context.app/feed/collectors",
        "url_name":"NFT追踪",
        "tip":"跟踪地址NFT活动"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220203/1643899830614075.png",
        "url":"https://mintyscore.com/rankings/projects?sort=minty_score&type=upcoming&order=desc&chain=all&name=",
        "url_name":"Mintyscore",
        "tip":"新项目热度排行，找金首选"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220203/1643897555791203.jpg",
        "url":"https://nftscan.com/",
        "url_name":"NFTSCAN",
        "tip":"专业的NFT浏览器和数据分析平台"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220203/1643897469961433.png",
        "url":"https://whatsminting.live/",
        "url_name":"Whatsminting",
        "tip":"NFT实时mint的情况"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220203/1643897276889793.png",
        "url":"https://nftnerds.ai/trending",
        "url_name":"NFTNerds",
        "tip":"Nft查询物品罕见度，rank值越低越好"
    }
]

const list_16_3 = [
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220127/1643294520430755.png",
        "url":"https://cryptorank.io/",
        "url_name":"Cryptorank",
        "tip":"数据分析工具，可看最新IDO/IEO项目数据"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220127/1643294329983296.png",
        "url":"https://www.nansen.ai/",
        "url_name":"Nansen",
        "tip":"跟聪明钱投项目，链上数据跟踪平台（付费）"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220127/1643295425914168.png",
        "url":"https://defillama.com/",
        "url_name":"defillama",
        "tip":"开放透明的DeFi TVL和分析"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220205/1644041563313324.png",
        "url":"https://xypher.io/Screener",
        "url_name":"鲸鱼狙击手",
        "tip":"监听ETH 上的大单信号，提高捕鯨精准度"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220127/1643295265581445.jpg",
        "url":"https://dappradar.com/",
        "url_name":"DappRadar",
        "tip":"Dapp商店"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220328/1648465621242641.png",
        "url":"https://www.footprint.network/dashboards",
        "url_name":"Footprint",
        "tip":"一站式可视化区块链数据分析平台，免费享受区块链数据分析"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220208/1644330090880396.png",
        "url":"https://glassnode.com/",
        "url_name":"Glassnode",
        "tip":"链上数据分析工具，提供中文版周报"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220217/1645073627255718.png",
        "url":"https://tool.imtoken.xyz/",
        "url_name":"Blockscan Chat",
        "tip":"通过钱包地址发起聊天的工具"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220210/1644426675650946.png",
        "url":"https://bloxy.info/zh",
        "url_name":"Bloxy",
        "tip":"代币数据可视化分析工具"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220208/1644325369749184.png",
        "url":"https://l2beat.com/",
        "url_name":"l2beat",
        "tip":"查询Layer2网络锁仓量排行榜"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220208/1644306667317972.png",
        "url":"https://tool.imtoken.xyz/coinmarketbook.cc",
        "url_name":"交易深度检查",
        "tip":"多币种市场深度检查工具"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220205/1644056873481481.png",
        "url":"https://nftbank.ai/",
        "url_name":"NFT投资管理",
        "tip":"NFT投资组合跟踪、价格搜索、发现新机会"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220205/1644056214491674.png",
        "url":"https://www.tokenterminal.com/",
        "url_name":"Token terminal",
        "tip":"数据可视化做的很棒"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220205/1644041970531900.png",
        "url":"https://www.coinglass.com/zh",
        "url_name":"全网合约数据",
        "tip":"全网合约实时数据，比较各大交易所"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220205/1644040168121762.png",
        "url":"https://ultrasound.money/",
        "url_name":"ETH燃烧分析",
        "tip":"ETH燃烧数据分析网站，发现最新优质项目"
    }
]

const list_16_4 = [
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220208/1644332774801106.png",
        "url":"https://coinmarketcap.com/",
        "url_name":"CoinMarketCap",
        "tip":"全面的币种价格信息"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220208/1644332832770476.png",
        "url":"https://coingecko.com/",
        "url_name":"Coingecko",
        "tip":"更新及时的价格信息"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220210/1644423773809366.png",
        "url":"https://www.feixiaohao.co/",
        "url_name":"非小号",
        "tip":"专业行情查看工具，国内用户体验好"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/other/20220208/1644333795195481.jpeg",
        "url":"https://www.dextools.io/app/",
        "url_name":"DexTools",
        "tip":"DEX看线工具"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220208/1644333569993562.png",
        "url":"https://www.coinbase.com/",
        "url_name":"CoinBase",
        "tip":"CoinBase交易所"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220208/1644333441593594.png",
        "url":"https://www.gate.ac/signup/432360",
        "url_name":"Gate.io",
        "tip":"Gate.io交易所，交易体验好"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220208/1644333261593995.png",
        "url":"https://accounts.binance.com/zh-CN/register?ref=173289907",
        "url_name":"Binance",
        "tip":"币安交易所"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220208/1644333154351209.png",
        "url":"https://trade.dydx.exchange/",
        "url_name":"DYDX",
        "tip":"期货交易所"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220208/1644333110474788.png",
        "url":"https://dex.guru/",
        "url_name":"DexGuru",
        "tip":"非常全且好用的dex交易聚合"
    }
]

const list_16_5 = [
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220205/1644039651470007.png",
        "url":"https://www.theblockresearch.com/",
        "url_name":"行业研报",
        "tip":"顶级行业研究报告，定期更新"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220322/1647941373276473.png",
        "url":"https://metascan.pro/",
        "url_name":"Metascan",
        "tip":"Web3 社交交易网络，具备推特内容筛选功能"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/other/20220317/1647519850110633.jpeg",
        "url":"https://www.alexdphan.com/research/library-of-web3",
        "url_name":"Web3 图书馆",
        "tip":"里面有科普、工具和项目介绍，适合新人学习"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220316/1647443708315482.png",
        "url":"https://www.panewslab.com/",
        "url_name":"PANews",
        "tip":"国内优质新闻门户"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220301/1646099664398016.png",
        "url":"https://www.odaily.news/",
        "url_name":"odaily",
        "tip":"星球日报，高质量中文新闻"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220209/1644422352868849.png",
        "url":"https://www.theblockbeats.info/",
        "url_name":"BlockBeats",
        "tip":"律动新闻，中国区块链项目最敏锐的观察家之一"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220208/1644334419110616.png",
        "url":"https://www.cypherhunter.com/zh-hans/",
        "url_name":"Cypherhunter",
        "tip":"区块链行业加密智库"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220208/1644321484544466.png",
        "url":"https://www.coindesk.com/",
        "url_name":"coindesk",
        "tip":"实时币圈新闻资讯"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220207/1644198473616858.png",
        "url":"https://app.submirror.xyz/",
        "url_name":"Submirror",
        "tip":"mirror信息订阅"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220205/1644037930515347.png",
        "url":"https://www.similarweb.com/zh/",
        "url_name":"流量来源分析",
        "tip":"查看网站流量构成，关注近期流量上升情况"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220204/1643959133128029.png",
        "url":"https://airdropalert.com/new-airdrops",
        "url_name":"搜索趋势",
        "tip":"加密货币在推特的搜索曝光排行趋势"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220203/1643898602210600.png",
        "url":"https://chainbroker.io/",
        "url_name":"chainbroker",
        "tip":"一级市场最新投资情报"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220203/1643898064451642.png",
        "url":"https://en.whotwi.com/",
        "url_name":"whotwi",
        "tip":"kol推特跟踪神器"
    }
]

const list_16_6 = [
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220127/1643288452198152.png",
        "url":"https://info.uniswap.org/tokens#/tokens/0x38ec27c6f05a169e7ed03132bca7d0cfee93c2c5",
        "url_name":"Uniswap",
        "tip":"DEX交易"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220207/1644200977489824.png",
        "url":"https://www.dextools.io/app/",
        "url_name":"dextools",
        "tip":"DEX看线必备，支持ETH、BSC等多链"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220127/1643293824652207.png",
        "url":"https://pancakeswap.finance/swap",
        "url_name":"Pancake薄饼",
        "tip":"Bsc币安智能链龙头交易所"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220127/1643293921303260.jpg",
        "url":"https://app.sushi.com/swap",
        "url_name":"Sushiwap",
        "tip":"ETH/BSC/Polygon | 去中心化交易所"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220127/1643294143892859.png",
        "url":"https://openserum.ch/dex/#/",
        "url_name":"Serum",
        "tip":"Sol链去中心化订单簿交易所"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220127/1643294014836824.png",
        "url":"https://app.1inch.io/#/1/swap/ETH/DAI",
        "url_name":"1inch",
        "tip":"多链、多协议的代币交易聚合平台"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220207/1644200744412478.png",
        "url":"https://poocoin.app/trending",
        "url_name":"BSC看线工具",
        "tip":"查看Bsc币种事实k线"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220208/1644306910878206.png",
        "url":"https://vfat.tools/",
        "url_name":"Defi挖矿管理",
        "tip":"defi挖矿管理器"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220211/1644569913714571.png",
        "url":"https://apy.top/",
        "url_name":"APR",
        "tip":"多链DeFi收入查询平台"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220210/1644483398509174.png",
        "url":"https://apr999.com/",
        "url_name":"APR List",
        "tip":"提供Defi ARP对比"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220210/1644425232733787.png",
        "url":"https://bscproject.org/#/",
        "url_name":"BscProject",
        "tip":"BSC Defi项目排名"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220210/1644425051955333.png",
        "url":"https://www.defipulse.com/",
        "url_name":"Defi Pulse",
        "tip":"以太坊Defi项目排行榜"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220208/1644325196185352.png",
        "url":"https://cn.etherscan.com/gastracker",
        "url_name":"Gastracker",
        "tip":"查询实时Gas数据与智能合约Gas消耗排行榜。"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220208/1644324210623193.png",
        "url":"https://coindix.com/",
        "url_name":"Coindix",
        "tip":"查看最佳的稳定币收益，监控22条链条上的8000DeFi"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220127/1643289538589337.png",
        "url":"https://dexscreener.com/bsc/pancakeswap",
        "url_name":"找新币",
        "tip":""
    }
]

const list_16_7 = [
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220128/1643371147948673.png",
        "url":"https://earni.fi/",
        "url_name":"Earnifi",
        "tip":"查询钱包空投信息"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220315/1647335988317756.png",
        "url":"https://5sim.net/",
        "url_name":"5SIM",
        "tip":"一款万能的接码平台"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220205/1644039006687941.png",
        "url":"https://icodrops.com/",
        "url_name":"ICO预告",
        "tip":"获取最新ICO预告信息"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220204/1643958921504592.png",
        "url":"https://airdropalert.com/new-airdrops",
        "url_name":"空投警报",
        "tip":"各种空投，撸到手软"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220204/1643913344567723.png",
        "url":"https://hackmd.io/@hua833/airdrop/https%3A%2F%2Fhackmd.io%2F90yfbb4fRFmE35TbbAHg7A",
        "url_name":"空投教程",
        "tip":"12个多链项目，低成本交互，步驟詳细，持續更新"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220128/1643371393604920.png",
        "url":"https://blockchain.tokenview.com/cn/",
        "url_name":"tokenview",
        "tip":"最全公链区块数据查询"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220128/1643370977805055.png",
        "url":"https://coinlist.co/",
        "url_name":"Coinlist",
        "tip":"ICO龙头"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220128/1643370849974373.png",
        "url":"https://dropsearn.com/airdrops/",
        "url_name":"dropsearn",
        "tip":"项目活动、空投等信息"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220128/1643370740150625.jpg",
        "url":"https://coinowo.com/",
        "url_name":"coinowo",
        "tip":"发现空投"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220128/1643370666830260.png",
        "url":"https://airdrops.io/",
        "url_name":"airdrops",
        "tip":"免费空投"
    }
]

const list_16_8 = [
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220208/1644320605498611.png",
        "url":"https://www.feixiaohao.co/data/gray.html",
        "url_name":"灰度基金持仓",
        "tip":"灰度基金总持仓量、持仓成本、持仓变化"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220127/1643295651604667.png",
        "url":"https://www.dovemetrics.com/",
        "url_name":"融资数据库",
        "tip":"最新投融资情报"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220208/1644323138973845.png",
        "url":"https://a16z.com/",
        "url_name":"a16z",
        "tip":"传奇加密风投 a16z，永远重注下一个时代"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220210/1644424295392750.png",
        "url":"https://tokeninsight.com/zh/report",
        "url_name":"TokenInsight",
        "tip":"研究评级机构、提供中文版研报分析"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220210/1644423941198545.png",
        "url":"https://messari.io/research/grid-view",
        "url_name":"messari",
        "tip":"研究报告和工具"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220208/1644323306693180.png",
        "url":"http://www.threearrowscap.com/select-investments/",
        "url_name":"三箭资本",
        "tip":"三箭资本"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220208/1644322480534862.png",
        "url":"https://www.cypherhunter.com/",
        "url_name":"CypherHunter",
        "tip":"项目投资方查询"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220205/1644037677360555.png",
        "url":"https://www.crunchbase.com/",
        "url_name":"项目融资",
        "tip":"项目融资情况"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220204/1643958723982633.png",
        "url":"https://airtable.com/shrP7uEmnxbv7dUEV/tbllShMbhc5jooPRb/viwymTshkJuerbyeT?backgroundColor=orange",
        "url_name":"最新投融资汇总",
        "tip":"机构投资项目数据库，持续更新，（推荐）"
    }
]

const list_16_9 = [
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/other/20220128/1643371802583624.jpeg",
        "url":"https://explore.smartx.one/",
        "url_name":"智图区块链浏览器",
        "tip":"智图区块链浏览器"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220128/1643371757908449.png",
        "url":"https://polkascan.io/",
        "url_name":"Polkadot",
        "tip":"Polkadot 波卡区块浏览器"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220128/1643371676177205.png",
        "url":"https://btc.com/",
        "url_name":"BTC浏览器",
        "tip":"比特币浏览器"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220128/1643371603471258.png",
        "url":"https://explorer.solana.com/",
        "url_name":"Sol区块浏览器",
        "tip":"Solana(Sol)链上区块浏览器"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220128/1643371521920629.png",
        "url":"https://bscscan.com/",
        "url_name":"BSC浏览器",
        "tip":"BSC官方区块链浏览器"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220208/1644331416233798.png",
        "url":"https://etherscan.io/",
        "url_name":"ETH区块浏览器",
        "tip":"以太坊官方区块浏览器"
    }
]

const list_16_10 = [
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220208/1644330686859773.png",
        "url":"https://degenscore.com/",
        "url_name":"DegenScore",
        "tip":"钱包地址评分"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220128/1643372365865457.png",
        "url":"https://token.im/",
        "url_name":"imToken",
        "tip":"imToken 是一款移动端轻钱包 App"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220128/1643372243137092.jpg",
        "url":"https://phantom.app/",
        "url_name":"Phantom",
        "tip":"SOL链的钱包"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220128/1643372194177372.png",
        "url":"https://mathwallet.org/zh-cn/",
        "url_name":"麦子钱包",
        "tip":"跨链钱包"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220128/1643372120363757.png",
        "url":"https://www.tokenpocket.pro/",
        "url_name":"TokenPocket",
        "tip":"功能强大多链钱包"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220128/1643372040507819.png",
        "url":"https://metamask.io/",
        "url_name":"MetaMask",
        "tip":"浏览器钱包插件"
    }
]

const list_16_11 = [
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220208/1644321310684929.png",
        "url":"https://www.cointofu.com/cross-chain-bridge",
        "url_name":"Cointofu",
        "tip":"跨链桥聚合搜索，查询对比各大跨链桥费率与速度"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220208/1644321167646033.png",
        "url":"https://xpollinate.io/",
        "url_name":"xPollinate",
        "tip":"多链互跨工具"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220203/1643895718268249.png",
        "url":"https://cbridge.celer.network/#/transfer",
        "url_name":"Cbridge",
        "tip":"celer团队做的桥支持多链"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220203/1643895621641540.png",
        "url":"https://bridge.terra.money/",
        "url_name":"Terra Bridge",
        "tip":"terra官方桥"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220203/1643895505859576.png",
        "url":"https://wallet.polygon.technology/bridge/",
        "url_name":"polygon",
        "tip":"马蹄官方桥"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220203/1643895435296090.png",
        "url":"https://portalbridge.com/#/transfer",
        "url_name":"Wormhole",
        "tip":"在 Solana、以太坊、BSC、Terra 和 Polygon 之间无缝转移代币化资产。"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220203/1643895272524519.png",
        "url":"https://www.boringdao.com/",
        "url_name":"BoringDAO",
        "tip":"12条链跨链桥"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220203/1643895197576894.png",
        "url":"https://multichain.xyz/",
        "url_name":"Multichain",
        "tip":"去中心化跨链工具"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220203/1643895133481528.png",
        "url":"https://hop.exchange/",
        "url_name":"Hop跨链桥",
        "tip":"跨链桥"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220203/1643895067661416.png",
        "url":"https://www.allchainbridge.com/#/",
        "url_name":"SWFT",
        "tip":"多链币种一键跨链闪兑"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220203/1643894977157451.png",
        "url":"https://anyswap.exchange/#/router",
        "url_name":"AnySwap",
        "tip":"Anyswap多链路由，去中心化的跨链交换协议"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220203/1643894895140965.png",
        "url":"https://bridge.avax.network/login",
        "url_name":"bridge",
        "tip":"雪崩avax跨链桥"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220203/1643894704952483.png",
        "url":"https://tools.defieye.io/bridge/",
        "url_name":"defieye",
        "tip":"低损夸链桥"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220203/1643895337153860.png",
        "url":"https://www.baidu.com/",
        "url_name":"li.finance",
        "tip":"多对多connext的合作伙伴，跨链dex"
    }
]

const list_16_12 = [
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220208/1644331148239534.png",
        "url":"https://peisong.meituan.com/rider",
        "url_name":"美团骑手招募",
        "tip":"兄der     牛归，速回"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220326/1648298551442077.png",
        "url":"https://beta.layer3.xyz/",
        "url_name":"Layer3",
        "tip":"币圈猪八戒平台，任务发布平台"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220317/1647528601822528.png",
        "url":"https://betterdiscord.app/plugin/Translator",
        "url_name":"翻译插件",
        "tip":"Discord的翻译插件"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220317/1647528441859666.png",
        "url":"https://linktr.ee/",
        "url_name":"Linktree",
        "tip":"将你的所有社交媒体链接聚合到一个链接上"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220228/1646031188505540.png",
        "url":"https://vestlab.io/",
        "url_name":"Vestlab",
        "tip":"可以看项目解锁时间表、公私幕价格的工具"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220222/1645509354300263.png",
        "url":"https://0xsplits.xyz/",
        "url_name":"0xsplits",
        "tip":"资金分配的一个自动系统，适合Dao基金资金分配和项目资管分配"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220210/1644426130753779.png",
        "url":"https://dcabtc.com/",
        "url_name":"投资计算器",
        "tip":"BTC投资收益计算器、投资策略制定"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220209/1644421083913927.png",
        "url":"http://blockjob.cn/",
        "url_name":"Bock Job",
        "tip":"区块链求职/招聘平台"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220208/1644330599428000.png",
        "url":"https://www.brightid.org/",
        "url_name":"Brightid",
        "tip":"去中心化世界身份"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220208/1644328289412937.png",
        "url":"https://tools.defieye.io/transferfee",
        "url_name":"defieye",
        "tip":"可对比交易所提币手续费，降低成本"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220208/1644306518615904.png",
        "url":"https://coin360.com/",
        "url_name":"COIN360",
        "tip":"生成的彩色图表展示的市值变化与价格走势"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220208/1644306261812986.png",
        "url":"https://croco.finance/#/",
        "url_name":"无常损失计算",
        "tip":"Defi无常损失计算器"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220208/1644305299636594.png",
        "url":"https://cointool.app/vanityAddressGenerator/eth",
        "url_name":"ETH靓号生成",
        "tip":""
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220205/1644043541127383.png",
        "url":"https://l2y.com/activity/",
        "url_name":"活动日历",
        "tip":"Gamefi的各种活动统计"
    },
    {
        "url_img":"https://tool.imtoken.xyz/static/upload/image/20220205/1644040018652271.png",
        "url":"https://juicebox.money/#/projects",
        "url_name":"DAO资金管理",
        "tip":"Dao项目融资工具，people等众多项目曾在此募资"
    }
]

export default [
    {
        type: 'folder',
        url_name: '科学家工具',
        list: list_16_1
    },
    {   
        type: 'folder',
        url_name: 'NFT工具',
        list: list_16_2
    },
    {
        type: 'folder',
        url_name: '链上数据',
        list: list_16_3
    },
    {
        type: 'folder',
        url_name: '看行情',
        list: list_16_4
    },
    {
        type: 'folder',
        url_name: '币圈情报',
        list: list_16_5
    },
    {
        type: 'folder',
        url_name: 'DEX交易',
        list: list_16_6
    },
    {
        type: 'folder',
        url_name: 'IDO/空投',
        list: list_16_7
    },
    {
        type: 'folder',
        url_name: '投融资',
        list: list_16_8
    },
    {
        type: 'folder',
        url_name: '区块链浏览器',
        list: list_16_9
    },
    {
        type: 'folder',
        url_name: '数字钱包',
        list: list_16_10
    },
    {
        type: 'folder',
        url_name: '跨链网关',
        list: list_16_11
    },
    {
        type: 'folder',
        url_name: '更多工具',
        list: list_16_12
    }
]